import type { Landing } from "~/types/drupal_jsonapi";
import { createSignal, onCleanup, onMount, Show } from "solid-js";
import { isServer } from "solid-js/web";
import { urlRs } from "~/utils/url";
import { hasNoEscape } from "~/utils/no_escape";
import CTAs from "~/components/Landing/Components/CTAs";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";

import "./StackBar.css";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

export default function StackBar(props: { landing: Landing }) {
  const [viewportSizeProvider] = useViewportSizeContext();

  const [landingHeaderIsStacked, setLandingHeaderIsStacked] =
    createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    const currentScrollTop = document.documentElement.scrollTop;
    if (currentScrollTop >= window.innerHeight - 100) {
      setLandingHeaderIsStacked(true);
    } else {
      setLandingHeaderIsStacked(false);
    }
  };

  const settings = useDrupalSettingsContext();

  return (
    <>
      <div
        class="landing-stackbar"
        data-ga-zone="header-sticky"
        data-test="header-sticky"
        classList={{ stacked: landingHeaderIsStacked() }}
      >
        <Show when={!settings.switch_logos}>
          <Show
            when={hasNoEscape()}
            fallback={
              <a href={urlRs("front", "/")} title="Accueil" class="logo">
                <img
                  src="/images/logo-cogedim.svg"
                  alt="Cogedim"
                  height="40"
                  width="40"
                />{" "}
                Cogedim
              </a>
            }
          >
            <span class="logo">
              <img
                src="/images/logo-cogedim.svg"
                alt="Cogedim"
                height="40"
                width="40"
              />{" "}
              Cogedim
            </span>
          </Show>
        </Show>

        <div class="flex-line">
          <p class="h1" innerHTML={props.landing.title} />

          <Show when={!viewportSizeProvider.viewPortIsLessThan1024}>
            <div class="summary" data-test="summary">
              <span innerHTML={props.landing.typology_summary} />{" "}
              <span class="price" innerHTML={props.landing.price_summary} />
            </div>
          </Show>

          <CTAs landing={props.landing} desktopOnly />
        </div>
      </div>
    </>
  );
}
